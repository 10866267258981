<template>
  <video
    ref="videoRef"
    :width="width"
    :height="height"
    :loop="loop"
    class="transform-gpu"
    :class="[
      {
        'duration-400 transition-opacity ease-linear': effect === 'fade'
      },
      effect === 'fade' && 'opacity-0',
      effect === 'fade' && isVisible && 'opacity-100'
    ]"
    @ended="hasPlayedThrough = true"
    @canplaythrough="emit('loaded')"
  >
    <source v-for="source in sources" :key="source.type" :src="source.src" :type="source.type" />
  </video>
</template>

<script setup lang="ts">
import type { UseIntersectionObserverOptions } from '@vueuse/core'

interface VideoSource {
  src: string | undefined
  type: string | undefined
}

interface VideoProps {
  width: number
  height: number
  loop?: boolean
  sources: VideoSource[]
  effect?: 'fade' | 'none'
  observerOptions?: UseIntersectionObserverOptions
}

withDefaults(defineProps<VideoProps>(), {
  width: 360,
  height: 180,
  loop: false,
  effect: 'fade'
})

const videoRef = ref<HTMLVideoElement | null>(null)
const targetIsVisible = useElementVisibility(videoRef)
const isVisible = ref(true)
const isPlaying = ref(false)
const hasPlayedThrough = ref(false)

// eslint-disable-next-line
const emit = defineEmits<{ (event: 'loaded'): void }>()

defineExpose({
  videoRef
})

function playVideo() {
  if (videoRef.value) {
    videoRef.value.play()
  }
}

function pauseVideo() {
  if (videoRef.value) {
    videoRef.value.pause()
  }
}

watch(targetIsVisible, () => {
  if (targetIsVisible.value) {
    isVisible.value = true
    if (!isPlaying.value && !hasPlayedThrough.value) {
      emit('loaded')
      playVideo()
    }
  } else {
    pauseVideo()
  }
})
</script>
